<template>
  <div class="home">
    <v-row no-gutters>
      <v-col cols="7">
        <div @click="goBack()" class="mt-7 go-back">
          <img src="../assets/Images/back-black.svg" alt="cart" />
        </div>
        <div class="mt-3 font-30 butler-700">Create Customization - Step 4</div>
      </v-col>
      <v-col cols="5">
        <div class="mt-6 mb-3 ml-8 px-16">
          <p class="text-black mb-2">Select Status</p>
          <v-btn-toggle v-model="selectedStatus" dense color="black" class="select-color">
            <v-btn value="draft"> Draft </v-btn>

            <v-btn value="approve"> Approve </v-btn>

            <v-btn value="publish"> Publish </v-btn>
          </v-btn-toggle>
        </div>
      </v-col>
    </v-row>
    <v-card>
      <div class="container">
        <div class="row">
          <div class="col-6">
            <div class="category-tree-2">
              <h5>Pricing Details</h5>
              <ul>
                <li
                  style="display: block"
                  class="text-capitalize"
                  v-for="(category, index) in categories"
                  v-bind:key="index"
                >
                  {{ category.name }}

                  <ul>
                    <li
                      style="display: block"
                      v-for="(layer, index) in category.layers"
                      v-bind:key="index"
                      class="text-capitalize"
                    >
                      {{ layer.name }}
                      <span class="sub-text">- Change of {{ layer.typeOfLayer }}</span>
                      &nbsp;
                      <!-- 
                      <span
                        ><button
                          class="btn btn-small btn-outline-primary show-button"
                          v-if="
                            layer.typeOfLayer != 'Image' &&
                            layer.typeOfLayer != 'Embroidery'
                          "
                          @click="toggleLayerShow(category, layer)"
                        >
                          Color Mapping
                        </button></span
                      > -->
                      <!-- <div
                        v-if="
                          layer.typeOfLayer != 'Image' &&
                          layer.typeOfLayer != 'Embroidery' &&
                          layer.typeOfLayer != 'Embroidery Color' &&
                          layer.show
                        "
                      >
                        <div class="">
                          <div class="padding-10"></div>

                          <div>
                            <h6>Color Mapping Per Category</h6>
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Category</th>
                                  <th>Color</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(
                                    category, index
                                  ) in layer.colorMapping"
                                  v-bind:key="index"
                                >
                                  <td>{{ index + 1 }}</td>
                                  <td>{{ category.name }}</td>
                                  <td>
                                    <select
                                      class="form-control"
                                      id="model"
                                      v-model="category.id"
                                    >
                                      <option value="">None</option>
                                      <option value="1">Group 1</option>
                                      <option value="2">Group 2</option>
                                      <option value="3">Group 3</option>
                                      <option value="4">Group 4</option>
                                      <option value="5">Group 5</option>
                                      <option value="6">Group 6</option>
                                      <option value="7">Group 7</option>
                                      <option value="8">Group 8</option>
                                    </select>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div> -->

                      <!-- <div
                        v-if="
                          layer.typeOfLayer == 'Embroidery Color' && layer.show
                        "
                      >
                        <div class="">
                          <div class="padding-10"></div>

                          <div>
                            <h6>Embroidery Color Mapping Per Category</h6>
                            <table class="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Sr. No.</th>
                                  <th>Embroidery</th>
                                  <th>Color</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(
                                    category, index
                                  ) in layer.colorMapping"
                                  v-bind:key="index"
                                >
                                  <td>{{ index + 1 }}</td>
                                  <td>{{ category.name }}s</td>
                                  <td>
                                    <select
                                      class="form-control"
                                      id="model"
                                      v-model="category.id"
                                    >
                                      <option value="">None</option>
                                      <option value="1">Group 1</option>
                                      <option value="2">Group 2</option>
                                      <option value="3">Group 3</option>
                                      <option value="4">Group 4</option>
                                      <option value="5">Group 5</option>
                                      <option value="6">Group 6</option>
                                      <option value="7">Group 7</option>
                                      <option value="8">Group 8</option>
                                    </select>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div> -->
                      <ul>
                        <li
                          style="display: block"
                          v-for="(toggle, index) in layer.toggles"
                          v-bind:key="index"
                          class="text-capitalize mt-1"
                        >
                          <v-row no-gutters>
                            <v-col cols="2">{{ toggle.name }}</v-col>
                            <v-col cols="1">-</v-col>
                            <v-col cols="9">
                              <v-text-field v-model="toggle.price" label="price" outlined dense>{{
                                toggle.price
                              }}</v-text-field></v-col
                            >
                          </v-row>
                          <!-- <span
                            class="show-button"
                            v-if="
                              layer.typeOfLayer != 'Image' &&
                              layer.colorMapping &&
                              layer.colorMapping.length > 0 &&
                              !layer.show
                            "
                          >
                            <button
                              type="button"
                              @click="toggleToggleShowForColor(layer, toggle)"
                              class="btn btn-outline-info btn-sm"
                            >
                              <span v-show="!toggle.show">Show</span>
                              <span v-show="toggle.show">Hide</span>
                            </button></span
                          > -->
                          <!-- <div v-if="toggle.colorAssignment.length > 0">
                            <div class="category-section" v-if="toggle.show">
                              <div>
                                <h5>Customize Color for {{ toggle.name }}</h5>

                                <div
                                  class="row layer-section"
                                  v-for="colorNumber in toggle.colorAssignment"
                                  :key="colorNumber.index"
                                >
                                  <h6>
                                    Group {{ colorNumber.colorMappingId }}
                                  </h6>
                                  <div class="col-md-6" align="left">
                                    <label
                                      for="customRange2"
                                      class="form-label text-left"
                                      >Saturate
                                      {{
                                        colorNumber.colorObject.saturation
                                      }}</label
                                    >

                                    <input
                                      type="range"
                                      class="form-range"
                                      min="0"
                                      max="100"
                                      step="0.1"
                                      v-model="
                                        colorNumber.colorObject.saturation
                                      "
                                      @change="onColorChange()"
                                    />
                                  </div>
                                  <div class="col-md-6" align="left">
                                    <label for="customRange2" class="form-label"
                                      >Hue-rotate
                                      {{ colorNumber.colorObject.hue }}</label
                                    >
                                    <input
                                      type="range"
                                      class="form-range"
                                      min="0"
                                      max="360"
                                      step="0.1"
                                      id="customRange2"
                                      v-model="colorNumber.colorObject.hue"
                                      @change="onColorChange()"
                                    />
                                  </div>
                                  <div class="col-md-6" align="left">
                                    <label for="customRange2" class="form-label"
                                      >Brightness
                                      {{ colorNumber.colorObject.brightness }}
                                    </label>
                                    <input
                                      type="range"
                                      class="form-range"
                                      min="0"
                                      max="1"
                                      step="0.01"
                                      id="customRange2"
                                      v-model="
                                        colorNumber.colorObject.brightness
                                      "
                                      @change="onColorChange()"
                                    />
                                  </div>
                                  <div class="col-md-6" align="left">
                                    <label for="customRange2" class="form-label"
                                      >Opacity
                                      {{
                                        colorNumber.colorObject.opacity
                                      }}</label
                                    >
                                    <input
                                      type="range"
                                      class="form-range"
                                      min="0"
                                      max="1"
                                      step="0.01"
                                      id="customRange2"
                                      v-model="colorNumber.colorObject.opacity"
                                      @change="onColorChange()"
                                    />
                                  </div>
                                </div>
                                <div class="padding-10"></div>
                              </div>
                            </div>
                          </div> -->
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div v-if="customizationsLoaded" class="col-6 model-fixed">
            <CustomizationModel :customization="customization" :show="true" />
          </div>
        </div>

        <!-- <div v-if="productData">
        <a :href="frontEndUrl + 'customization?productId=' + productData.product._id"
          >Check link for product customization</a
        >
      </div> -->
      </div>
      <div @click="updateData" class="btn btn-success btn-lg btn-block ml-8 mb-4">Complete</div>
    </v-card>
  </div>
</template>

<script>
import _ from 'lodash';
import service from '../service/apiService';
import CustomizationModel from '@/components/customization/CustomizationModel.vue';

// @ is an alias to /src

export default {
  name: 'Home',
  components: { CustomizationModel },
  data() {
    return {
      customizationsLoaded: false,
      customization: {},
      frontEndUrl: process.env.VUE_APP_FRONT_END_URL,
      saturation: '',
      hue: '',
      brightness: '',
      opacity: '',
      colorSelection: '',
      embroiderySelection: '',
      productData: {},
      categories: [],
      model: ['Male', 'Female'],
      embroideries: [],
      bodytypes: ['Front', 'Back', 'Plus Front', 'Plus Back'],
      imageTypes: ['Core', 'Shading'],
      colors: ['Color 1', 'Color 2', 'Color 3', 'Color 4', 'Color 5'],
      selectedStatus: 'draft',
      statusArray: ['draft', 'approve', 'publish'],
    };
  },
  methods: {
    findEmbForEmbColor(category) {
      const finalLayer = _.find(category.layers, (n) => {
        const obj = n.typeOfLayer === 'Embroidery';
        return obj;
      });
      return finalLayer.embroideryMapping;
    },
    onColorChange() {
      // if (type === 'saturation') {
      //   this.saturation = evt.target.value;
      // } else if (type === 'hue') {
      //   this.hue = evt.target.value;
      // } else if (type === 'brightness') {
      //   this.brightness = evt.target.value;
      // } else {
      //   this.opacity = evt.target.value;
      // }
      this.$store.commit('setCustomization', this.customization);
    },
    uniqueColors(colorMapping) {
      console.log('colorMapping', colorMapping);
      // {
      //     colorMappingId: { type: Number },
      //     colorObject: {
      //         saturation: {
      //             type: Number,
      //             default: 0
      //         },
      //         hue: { type: Number, default: 0 },
      //         brightness: {
      //             type: Number,
      //             default: 0
      //         },
      //         opacity: {
      //             type: Number,
      //             default: 0
      //         }
      //     }
      // }
      /* eslint-disable */
      return _.chain(colorMapping).map('id').compact().uniq().orderBy().value();
    },

    async updateData() {
      this.dialog = false;
      this.productData.customizationStatus = this.selectedStatus;
      this.productData.categories = this.categories;
      const result = await service.updateProduct(this.$route.params.id, this.productData);
      if (result.status === 200) {
        this.$toasted.show('data saved!.', {
          theme: 'bubble',
          position: 'bottom-center',
          duration: 500,
        });
        this.categories = result.data.categories;
        this.productData = result.data;
      }
    },
    async getData() {
      const result = await service.getProduct(this.$route.params.id);
      this.customization = result.data;
      this.categories = result.data.categories;
      //this.hideAllToggle();
      this.gender = result.data.gender;
      this.embroideries = result.data.embroideries;
      this.selectedStatus = result.data.customizationStatus;
      this.productData = result.data;
      this.$store.commit('setCustomization', this.customization);
      if (result.data && result.data.product && result.data.product.deliveryDetails.length > 0) {
        _.each(result.data.product.deliveryDetails, (n) => {
          if (n.mrp > 0) {
            this.$store.commit('setProductPrice', n.mrp);
            console.log('here result', n.mrp);
            return false;
          }
        });
      }
      this.customizationsLoaded = true;
    },
    goBack() {
      history.go(-1);
    },
    // hideAllToggle() {
    //   _.each(this.categories, (category) => {
    //     _.each(category.layers, (layer) => {
    //       _.each(layer.toggles, (toggle) => {
    //         toggle.show = false;
    //       });
    //     });
    //   });
    // },
  },
  async mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.model-wrapper {
  position: fixed;
  left: 50%;
}
.next-button-section {
  margin: 30px 0;
}
.image-upload-section {
  padding: 20px;
}
.sub-text {
  font-size: 12px;
  color: #999;
}
.category-tree {
  position: fixed;
  zoom: 0.7;
  top: 10px;
}
.embroidery-section {
  padding: 20px;
  background: #e0e1ff;
  border-radius: 20px;
}
.category-section {
  padding: 20px;
  background: #e0eeff;
  border-radius: 20px;
}
.layer-section {
  background: #e0fcfe;
  border-radius: 20px;
  padding: 20px;
}
.layer-section + .layer-section {
  margin-top: 20px;
}
.toggle-section {
  background: #fee0e0;
  border-radius: 20px;
  padding: 20px;
}

.delete-button-holder {
  position: relative;
}
.delete-button {
  position: absolute;
  zoom: 0.7;
  left: -67px;
  top: 19px;
}

.margin-left-1 {
  margin: 10px 0px 10px 80px;
}
.pull-right {
  float: right;
}
.padding-10 {
  padding: 10px;
}
.custom-range {
  margin-left: 30px;
  width: 220px;
}
.category {
  margin-top: 80px;
  .model-name {
    font-size: 15px;
  }
  .category-name {
    font-size: 32px;
  }
  .layer {
    margin-top: 20px;
    select {
      font-size: 15px;
    }
    font-size: 26px;
    margin-left: 20px;
    .toggle {
      .toggle-name {
        margin-top: 10px;
      }
      font-size: 20px;
      margin-left: 20px;
      .images-upload {
        font-size: 16px;
        margin-left: 20px;
        .image-upload-section {
          font-size: 14px;
          margin-left: 20px;
          width: 100px;
          height: 100px;
          border: 1px solid #ccc;
          display: inline-block;
          text-align: center;
          line-height: 100px;
        }
      }
    }
  }
}
.select-color {
  border: 1px solid rgb(0, 0, 0) !important;
  // padding-left: 4px;
}
.v-text-field {
  width: 80px !important;
  height: 50px !important;
}
.go-back {
  cursor: pointer !important;
}
.model-fixed {
  position: fixed;
  left: 50%;
  top: 10%;
  // transform: translateX(-25%);
  // transform: translateY(-5%);
  // transform: scaleY(0.75);
  transform: scale(0.63);
  padding-top: 3em;
  max-height: 400px !important;
  max-width: 750px !important;
  bottom: 5% !important;
}
@media only screen and (max-height: 679px) {
  .model-fixed {
    position: fixed;
    left: 50%;
    top: 10%;
    transform: translateX(-55%);
    transform: translateY(-5%);
    transform: scaleY(0.6);
    transform: scale(0.5);
    padding: 3em;
    max-height: 260px !important;
    max-width: 720px !important;
  }
}
</style>
